import React from 'react'
import styles from './BannerSection.module.scss'
import Button from '../Button/Button'
import { reachGoal } from '../metrika'

const BannerSection = ({setShowPopup}) => (
  <section className={styles.section}>
    <div className={styles.sectionWrapper}>
      <div className={styles.label}>Detection of publishers’ frauds</div>
      <div className={styles.title}>
        PROTECTION AGAINST REATTRIBUTIONS <br />
        IN AFFILIATE NETWORKS AND RETARGETING
      </div>
      <div className={styles.content}>
        Remember: time is money. The more time that goes by once <br />
        ou discover fraudulent tactics, the more you lose.
      </div>
      <Button onClick={() => {setShowPopup(true);reachGoal('REG_FORM_BTN_2');}}>Stop paying for fraud</Button>
    </div>
    <svg
      style={{
        position: 'absolute',
        left: '50%',
        top: -15,
        transform: 'translateX(-50%) rotate(-15deg)',
        marginLeft: -342
      }}
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <path
          d="M1.12205 1.12205C2.61811 -0.374016 5.04371 -0.374016 6.53977 1.12205L44.4639 39.0461C45.9599 40.5422 45.9599 42.9678 44.4639 44.4639C42.9678 45.9599 40.5422 45.9599 39.0461 44.4639L1.12205 6.53978C-0.374016 5.04371 -0.374016 2.61811 1.12205 1.12205Z"
          fill="url(#paint0_linear10)"
        />
        <path
          d="M1.12207 44.4639C-0.373998 42.9678 -0.373998 40.5422 1.12207 39.0461L39.0461 1.12205C40.5422 -0.374015 42.9678 -0.374015 44.4639 1.12205C45.9599 2.61811 45.9599 5.04371 44.4639 6.53978L6.53979 44.4639C5.04373 45.9599 2.61813 45.9599 1.12207 44.4639Z"
          fill="url(#paint1_linear11)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear10"
          x1="22.793"
          y1="-0.542635"
          x2="22.793"
          y2="81.9465"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1A6DFF" />
          <stop offset="1" stopColor="#C822FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear11"
          x1="22.793"
          y1="-0.542635"
          x2="22.793"
          y2="81.9465"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1A6DFF" />
          <stop offset="1" stopColor="#C822FF" />
        </linearGradient>
      </defs>
    </svg>
    <svg
      style={{
        position: 'absolute',
        left: '50%',
        top: 271,
        transform: 'translateX(-50%) rotate(-20deg)',
        marginLeft: -577
      }}
      width="165"
      height="164"
      viewBox="0 0 165 164"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        opacity="0.5"
        x="0.585938"
        width="163.881"
        height="163.881"
        rx="20"
        fill="url(#paint0_linear12)"
      />
      <defs>
        <linearGradient
          id="paint0_linear12"
          x1="82.5264"
          y1="-1.95077"
          x2="82.5264"
          y2="294.597"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1A6DFF" />
          <stop offset="1" stopColor="#C822FF" />
        </linearGradient>
      </defs>
    </svg>
    <svg
      style={{
        position: 'absolute',
        right: '50%',
        top: 218,
        transform: 'translateX(50%)',
        marginRight: -571
      }}
      width="196"
      height="196"
      viewBox="0 0 196 196"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.8"
        d="M185.467 98C185.467 146.649 146.244 186 97.9668 186C49.69 186 10.4668 146.649 10.4668 98C10.4668 49.3507 49.69 10 97.9668 10C146.244 10 185.467 49.3507 185.467 98Z"
        stroke="url(#paint0_linear14)"
        strokeWidth="20"
      />
      <defs>
        <linearGradient
          id="paint0_linear14"
          x1="418.847"
          y1="317.52"
          x2="493.89"
          y2="125.779"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#70DDFF" />
          <stop offset="1" stopColor="#169CFE" />
        </linearGradient>
      </defs>
    </svg>
  </section>
)

export default BannerSection

import React, { useState, useEffect } from 'react'
import styles from './App.module.css'
import Header from './components/Header/Header'
import CommentsSection from './components/CommetnsSection/CommentsSection'
import SolutionSection from './components/SolutionSection/SolutionSection'
import ExplanationSection from './components/ExplanationSection/ExplanationSection'
import BannerSection from './components/BannerSection/BannerSection'
import CarouselSection from './components/CarouselSection/CarouselSection'
import BrandSection from './components/BrandSection/BrandSection'
import CasesSection from './components/CasesSection/CasesSection'
import SaveMoneySection from './components/SaveMoneySection/SaveMoneySection'
import CompaniesSection from './components/CompaniesSection/CompaniesSection'
import FormSection from './components/FormSection/FormSection'
import Form from './components/Form/Form'
import { reachGoal } from './components/metrika'

export default () => {
  const [showPopup, setShowPopup] = useState(false)

  useEffect(() => {
    window.didReach = {
      half: false,
      end: false
    }
    document.addEventListener('scroll', handleScroll)
    return function cleanup() {
      window.didReach = undefined
      document.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleScroll = e => {
    if (
      document.documentElement.scrollTop + window.innerHeight >=
        document.documentElement.scrollHeight / 2 + window.innerHeight / 2
    ) {
      if (!window.didReach.half) {
        reachGoal('REACH_HALF_PAGE')
        window.didReach.half = true
      }
    }
    if (
      document.documentElement.scrollTop + window.innerHeight >=
      document.documentElement.scrollHeight - window.innerHeight
    ) {
      if (!window.didReach.end) {
        reachGoal('REACH_END_PAGE')
        window.didReach.end = true
      }
    }
  }
  return (
    <div className={styles.mainWrapper}>
      <Header setShowPopup={setShowPopup} />
      <CommentsSection />
      <SolutionSection setShowPopup={setShowPopup} />
      <ExplanationSection />
      <BannerSection setShowPopup={setShowPopup} />
      <CarouselSection />
      <BrandSection setShowPopup={setShowPopup} />
      <CasesSection />
      <SaveMoneySection setShowPopup={setShowPopup} />
      <CompaniesSection />
      <FormSection />
      <div
        style={{
          opacity: showPopup ? null : 0,
          pointerEvents: showPopup ? 'all' : 'none'
        }}
        className={styles.popup}
      >
        <Form
          style={{
            zIndex: 1,
            position: 'fixed',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)'
          }}
          requestCallback={() => setShowPopup(false)}
        />
        <div onClick={() => setShowPopup(false)} className={styles.bg}></div>
      </div>
    </div>
  )
}

import React from 'react'
import styles from './BrandSection.module.scss'
import Button from '../Button/Button'

import brand_dots from '../../assets/brand_dots.svg'
import brand_card from '../../assets/brand_card.svg'
import { reachGoal } from '../metrika'

const BrandSection = ({ setShowPopup }) => (
  <section className={styles.section}>
    <div className={styles.sectionWrapper}>
      <div className={styles.label}>Brand protection</div>
      <div className={styles.title}>
        PREVENT THE USE OF THE BRAND CONTEXTUAL <br />
        ADVERTISING IN THE AFFILIATE CHANNEL
      </div>
      <div className={styles.content}>
        Prevent redirects with affiliate links inside the Type In traffic. The
        solution <br /> allows you to save the budget by 20-30%. We check the
        results of paid <br /> searches for different cities 24 hours per day as
        well as all variants <br /> of typos which allow us to identify all
        fraudulent partners.
      </div>
      <Button
        onClick={() => {
          setShowPopup(true)
          reachGoal('REG_FORM_BTN_3')
        }}
      >
        registration
      </Button>
      <img className={styles.card} src={brand_card} alt="" />
    </div>
    <div className={styles.bg}>
      <svg width="1000" height="499" viewBox="0 0 1000 499" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.77778e-06 468.226L4.10887e-05 30.0001C4.25372e-05 13.4315 13.4315 6.63393e-05 30 6.77878e-05L970 8.893e-05C986.569 9.03785e-05 1000 13.4315 1000 30.0001L1000 414.188C1000 430.088 987.595 443.226 971.722 444.139L31.7217 498.176C14.5048 499.166 1.27014e-06 485.471 2.77778e-06 468.226Z" fill="#263D4C"/>
      </svg>
    </div>
    <img className={styles.dots} src={brand_dots} alt="" />
  </section>
)

export default BrandSection

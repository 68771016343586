import React from 'react'
import styles from './CasesSection.module.scss'
import cases_bg from '../../assets/cases_bg.svg'

const CasesSection = props => (
  <section className={styles.section}>
    <div className={styles.sectionWrapper}>
      <img className={styles.bg} src={cases_bg} alt="" />
      <div style={{ top: 40 }} className={styles.item}>
        <div className={styles.title}>38% Fraud</div>
        <div className={styles.subtitle}>myToys</div>
        <div className={styles.content}>
          An increase in the number of active <br />
          webmasters by 33%
        </div>
      </div>
      <div style={{ top: 328 }} className={styles.item}>
        <div className={styles.title}>44% Fraud, brand bidding</div>
        <div className={styles.subtitle}>MediaMarkt</div>
        <div className={styles.content}>
          80% reduction in cashback non-payment <br />
          complaints
        </div>
      </div>
      <div style={{ top: 616 }} className={styles.item}>
        <div className={styles.title}>15% brand bidding</div>
        <div className={styles.subtitle}>Xiaomi</div>
        <div className={styles.content}>
          23% reduction in the cost of own <br />
          contextual advertising
        </div>
      </div>
    </div>
  </section>
)

export default CasesSection

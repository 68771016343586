import React from "react";
import styles from './CompaniesSection.module.scss'

import row_1 from '../../assets/row_1.svg'
import row_2 from '../../assets/row_2.svg'
import row_3 from '../../assets/row_3.svg'

const CompaniesSection = (props) => (
  <section className={styles.section}>
    <div className={styles.sectionWrapper}>
      <div className={styles.label}>working around the world</div>
      <div className={styles.title}>More than 160 companies work with US</div>
      <div className={styles.icons}>
        <img className={styles.row} src={row_1} alt=""/>
        <img className={styles.row} src={row_2} alt=""/>
        <img className={styles.row} src={row_3} alt=""/>
      </div>
    </div>
  </section>
);

export default CompaniesSection;

import React from 'react'
import styles from './ExplanationSection.module.scss'

const ExplanationSection = props => (
  <section className={styles.section}>
    <div className={styles.sectionWrapper}>
      <div style={{ marginTop: 100 }} className={styles.col}>
        <div style={{ borderRadius: '30px 0 0 0' }} className={styles.colItem}>
          <div className={styles.itemTitle}>COOKIE STUFFING</div>
          <div className={styles.itemContent}>
            The affiliate loads cookies onto the user’s computer simply because
            the user visited the site. After that, the affiliate scores a
            commission for potential future purchases, whether the user clicked
            the affiliate link or not.
          </div>
        </div>
        <div style={{ borderRadius: '0 0 0 10px' }} className={styles.colItem}>
          <div className={styles.itemTitle}>BRAND BIDDING</div>
          <div className={styles.itemContent}>
            A publisher uses brand bidding, сlient goes to your website with a
            partner link (directly/through the publisher’s showcase/through the
            publisher’s coupon-site). As a result you pay the publisher for the
            orders that you receive in any case and bids rise in contextual
            advertising auction.
          </div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.colItem}>
          <div className={styles.itemTitle}>ADWARE</div>
          <div className={styles.itemContent}>
            Programs such as spyware, pop-ups, or pop-unders secretly installed
            on users’ computers without their permission or knowledge. These
            programs are used to artificially inflate traffic figures reported
            to an advertiser.
          </div>
        </div>
        <div className={[styles.colItem, styles.mainItem].join(' ')}>
          <div className={styles.itemLabel}>illegitimate money</div>
          <div className={styles.itemTitle}>What Is Affiliate Fraud?</div>
        </div>
        <div className={styles.colItem}>
          <div className={styles.itemTitle}>IFRAME</div>
          <div className={styles.itemContent}>
            The fraudster includes a 1-pixel square iFrame script in an ad. When
            users view the page, another (hidden) link is loaded through the
            pixel. The user never sees the ad it's too small but the advertiser
            will still be charged for the impression.
          </div>
        </div>
      </div>
      <div style={{ marginTop: 60 }} className={styles.col}>
        <div style={{ borderRadius: '0 10px 0 0' }} className={styles.colItem}>
          <div className={styles.itemTitle}>IP SPOOFING</div>
          <div className={styles.itemContent}>
            The attacker creates a fake IP address to hide the sender’s
            identity. This could allow affiliates to artificially inflate the
            traffic they drive to an advertiser’s site by repeatedly clicking
            links on their own sites.
          </div>
        </div>
        <div className={styles.colItem}>
          <div className={styles.itemTitle}>POSTBACK</div>
          <div className={styles.itemContent}>
            A method of directing and tracking conversion data. If fraudsters
            can obtain the transaction ID and address for approval, they can
            attempt to bypass the actual advertiser and claim commissions for
            conversions that never happened.
          </div>
        </div>
        <div style={{ borderRadius: '0 0 30px 0' }} className={styles.colItem}>
          <div className={styles.itemTitle}>DISCOUNT CODES</div>
          <div className={styles.itemContent}>
            Users may click through to an advertiser from an honest affiliate,
            then leave to search for a discount code prior to checkout. The
            dishonest affiliate who provides the coupon drops a cookie on the
            user's browser, giving them credit for the sale.
          </div>
        </div>
      </div>
      <svg
        style={{ position: 'absolute', top: -7, left: 276, zIndex: -1 }}
        width="134"
        height="134"
        viewBox="0 0 134 134"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.15">
          <mask
            id="mask0"
            masktype="alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="134"
            height="134"
          >
            <circle cx="67" cy="67" r="67" fill="#343A40" />
          </mask>
          <g mask="url(#mask0)">
            <circle cx="9.5713" cy="9.57142" r="0.87013" fill="#343A40" />
            <circle cx="9.5713" cy="20.0131" r="0.87013" fill="#343A40" />
            <circle cx="9.5713" cy="30.4545" r="0.87013" fill="#343A40" />
            <circle cx="9.5713" cy="40.8961" r="0.87013" fill="#343A40" />
            <circle cx="9.5713" cy="51.3377" r="0.87013" fill="#343A40" />
            <circle cx="9.5713" cy="61.7793" r="0.87013" fill="#343A40" />
            <circle cx="9.5713" cy="72.2208" r="0.87013" fill="#343A40" />
            <circle cx="9.5713" cy="82.6624" r="0.87013" fill="#343A40" />
            <circle cx="9.5713" cy="93.1039" r="0.87013" fill="#343A40" />
            <circle cx="9.5713" cy="103.545" r="0.87013" fill="#343A40" />
            <circle cx="9.5713" cy="113.987" r="0.87013" fill="#343A40" />
            <circle cx="9.5713" cy="124.429" r="0.87013" fill="#343A40" />
            <circle cx="20.0132" cy="9.57142" r="0.87013" fill="#343A40" />
            <circle cx="20.0132" cy="20.0131" r="0.87013" fill="#343A40" />
            <circle cx="20.0132" cy="30.4545" r="0.87013" fill="#343A40" />
            <circle cx="20.0132" cy="40.8961" r="0.87013" fill="#343A40" />
            <circle cx="20.0132" cy="51.3377" r="0.87013" fill="#343A40" />
            <circle cx="20.0132" cy="61.7793" r="0.87013" fill="#343A40" />
            <circle cx="20.0132" cy="72.2208" r="0.87013" fill="#343A40" />
            <circle cx="20.0132" cy="82.6624" r="0.87013" fill="#343A40" />
            <circle cx="20.0132" cy="93.1039" r="0.87013" fill="#343A40" />
            <circle cx="20.0132" cy="103.545" r="0.87013" fill="#343A40" />
            <circle cx="20.0132" cy="113.987" r="0.87013" fill="#343A40" />
            <circle cx="20.0132" cy="124.429" r="0.87013" fill="#343A40" />
            <circle cx="30.4546" cy="9.57142" r="0.87013" fill="#343A40" />
            <circle cx="30.4546" cy="20.0131" r="0.87013" fill="#343A40" />
            <circle cx="30.4546" cy="30.4545" r="0.87013" fill="#343A40" />
            <circle cx="30.4546" cy="40.8961" r="0.87013" fill="#343A40" />
            <circle cx="30.4546" cy="51.3377" r="0.87013" fill="#343A40" />
            <circle cx="30.4546" cy="61.7793" r="0.87013" fill="#343A40" />
            <circle cx="30.4546" cy="72.2208" r="0.87013" fill="#343A40" />
            <circle cx="30.4546" cy="82.6624" r="0.87013" fill="#343A40" />
            <circle cx="30.4546" cy="93.1039" r="0.87013" fill="#343A40" />
            <circle cx="30.4546" cy="103.545" r="0.87013" fill="#343A40" />
            <circle cx="30.4546" cy="113.987" r="0.87013" fill="#343A40" />
            <circle cx="30.4546" cy="124.429" r="0.87013" fill="#343A40" />
            <circle cx="40.896" cy="9.57142" r="0.87013" fill="#343A40" />
            <circle cx="40.896" cy="20.0131" r="0.87013" fill="#343A40" />
            <circle cx="40.896" cy="30.4545" r="0.87013" fill="#343A40" />
            <circle cx="40.896" cy="40.8961" r="0.87013" fill="#343A40" />
            <circle cx="40.896" cy="51.3377" r="0.87013" fill="#343A40" />
            <circle cx="40.896" cy="61.7793" r="0.87013" fill="#343A40" />
            <circle cx="40.896" cy="72.2208" r="0.87013" fill="#343A40" />
            <circle cx="40.896" cy="82.6624" r="0.87013" fill="#343A40" />
            <circle cx="40.896" cy="93.1039" r="0.87013" fill="#343A40" />
            <circle cx="40.896" cy="103.545" r="0.87013" fill="#343A40" />
            <circle cx="40.896" cy="113.987" r="0.87013" fill="#343A40" />
            <circle cx="40.896" cy="124.429" r="0.87013" fill="#343A40" />
            <circle cx="51.3374" cy="9.57142" r="0.87013" fill="#343A40" />
            <circle cx="51.3374" cy="20.0131" r="0.87013" fill="#343A40" />
            <circle cx="51.3374" cy="30.4545" r="0.87013" fill="#343A40" />
            <circle cx="51.3374" cy="40.8961" r="0.87013" fill="#343A40" />
            <circle cx="51.3374" cy="51.3377" r="0.87013" fill="#343A40" />
            <circle cx="51.3374" cy="61.7793" r="0.87013" fill="#343A40" />
            <circle cx="51.3374" cy="72.2208" r="0.87013" fill="#343A40" />
            <circle cx="51.3374" cy="82.6624" r="0.87013" fill="#343A40" />
            <circle cx="51.3374" cy="93.1039" r="0.87013" fill="#343A40" />
            <circle cx="51.3374" cy="103.545" r="0.87013" fill="#343A40" />
            <circle cx="51.3374" cy="113.987" r="0.87013" fill="#343A40" />
            <circle cx="51.3374" cy="124.429" r="0.87013" fill="#343A40" />
            <circle cx="61.7793" cy="9.57142" r="0.87013" fill="#343A40" />
            <circle cx="61.7793" cy="20.0131" r="0.87013" fill="#343A40" />
            <circle cx="61.7793" cy="30.4545" r="0.87013" fill="#343A40" />
            <circle cx="61.7793" cy="40.8961" r="0.87013" fill="#343A40" />
            <circle cx="61.7793" cy="51.3377" r="0.87013" fill="#343A40" />
            <circle cx="61.7793" cy="61.7793" r="0.87013" fill="#343A40" />
            <circle cx="61.7793" cy="72.2208" r="0.87013" fill="#343A40" />
            <circle cx="61.7793" cy="82.6624" r="0.87013" fill="#343A40" />
            <circle cx="61.7793" cy="93.1039" r="0.87013" fill="#343A40" />
            <circle cx="61.7793" cy="103.545" r="0.87013" fill="#343A40" />
            <circle cx="61.7793" cy="113.987" r="0.87013" fill="#343A40" />
            <circle cx="61.7793" cy="124.429" r="0.87013" fill="#343A40" />
            <circle cx="72.2207" cy="9.57142" r="0.87013" fill="#343A40" />
            <circle cx="72.2207" cy="20.0131" r="0.87013" fill="#343A40" />
            <circle cx="72.2207" cy="30.4545" r="0.87013" fill="#343A40" />
            <circle cx="72.2207" cy="40.8961" r="0.87013" fill="#343A40" />
            <circle cx="72.2207" cy="51.3377" r="0.87013" fill="#343A40" />
            <circle cx="72.2207" cy="61.7793" r="0.87013" fill="#343A40" />
            <circle cx="72.2207" cy="72.2208" r="0.87013" fill="#343A40" />
            <circle cx="72.2207" cy="82.6624" r="0.87013" fill="#343A40" />
            <circle cx="72.2207" cy="93.1039" r="0.87013" fill="#343A40" />
            <circle cx="72.2207" cy="103.545" r="0.87013" fill="#343A40" />
            <circle cx="72.2207" cy="113.987" r="0.87013" fill="#343A40" />
            <circle cx="72.2207" cy="124.429" r="0.87013" fill="#343A40" />
            <circle cx="82.6621" cy="9.57142" r="0.87013" fill="#343A40" />
            <circle cx="82.6621" cy="20.0131" r="0.87013" fill="#343A40" />
            <circle cx="82.6621" cy="30.4545" r="0.87013" fill="#343A40" />
            <circle cx="82.6621" cy="40.8961" r="0.87013" fill="#343A40" />
            <circle cx="82.6621" cy="51.3377" r="0.87013" fill="#343A40" />
            <circle cx="82.6621" cy="61.7793" r="0.87013" fill="#343A40" />
            <circle cx="82.6621" cy="72.2208" r="0.87013" fill="#343A40" />
            <circle cx="82.6621" cy="82.6624" r="0.87013" fill="#343A40" />
            <circle cx="82.6621" cy="93.1039" r="0.87013" fill="#343A40" />
            <circle cx="82.6621" cy="103.545" r="0.87013" fill="#343A40" />
            <circle cx="82.6621" cy="113.987" r="0.87013" fill="#343A40" />
            <circle cx="82.6621" cy="124.429" r="0.87013" fill="#343A40" />
            <circle cx="93.104" cy="9.57142" r="0.87013" fill="#343A40" />
            <circle cx="93.104" cy="20.0131" r="0.87013" fill="#343A40" />
            <circle cx="93.104" cy="30.4545" r="0.87013" fill="#343A40" />
            <circle cx="93.104" cy="40.8961" r="0.87013" fill="#343A40" />
            <circle cx="93.104" cy="51.3377" r="0.87013" fill="#343A40" />
            <circle cx="93.104" cy="61.7793" r="0.87013" fill="#343A40" />
            <circle cx="93.104" cy="72.2208" r="0.87013" fill="#343A40" />
            <circle cx="93.104" cy="82.6624" r="0.87013" fill="#343A40" />
            <circle cx="93.104" cy="93.1039" r="0.87013" fill="#343A40" />
            <circle cx="93.104" cy="103.545" r="0.87013" fill="#343A40" />
            <circle cx="93.104" cy="113.987" r="0.87013" fill="#343A40" />
            <circle cx="93.104" cy="124.429" r="0.87013" fill="#343A40" />
            <circle cx="103.545" cy="9.57142" r="0.87013" fill="#343A40" />
            <circle cx="103.545" cy="20.0131" r="0.87013" fill="#343A40" />
            <circle cx="103.545" cy="30.4545" r="0.87013" fill="#343A40" />
            <circle cx="103.545" cy="40.8961" r="0.87013" fill="#343A40" />
            <circle cx="103.545" cy="51.3377" r="0.87013" fill="#343A40" />
            <circle cx="103.545" cy="61.7793" r="0.87013" fill="#343A40" />
            <circle cx="103.545" cy="72.2208" r="0.87013" fill="#343A40" />
            <circle cx="103.545" cy="82.6624" r="0.87013" fill="#343A40" />
            <circle cx="103.545" cy="93.1039" r="0.87013" fill="#343A40" />
            <circle cx="103.545" cy="103.545" r="0.87013" fill="#343A40" />
            <circle cx="103.545" cy="113.987" r="0.87013" fill="#343A40" />
            <circle cx="103.545" cy="124.429" r="0.87013" fill="#343A40" />
            <circle cx="113.987" cy="9.57142" r="0.87013" fill="#343A40" />
            <circle cx="113.987" cy="20.0131" r="0.87013" fill="#343A40" />
            <circle cx="113.987" cy="30.4545" r="0.87013" fill="#343A40" />
            <circle cx="113.987" cy="40.8961" r="0.87013" fill="#343A40" />
            <circle cx="113.987" cy="51.3377" r="0.87013" fill="#343A40" />
            <circle cx="113.987" cy="61.7793" r="0.87013" fill="#343A40" />
            <circle cx="113.987" cy="72.2208" r="0.87013" fill="#343A40" />
            <circle cx="113.987" cy="82.6624" r="0.87013" fill="#343A40" />
            <circle cx="113.987" cy="93.1039" r="0.87013" fill="#343A40" />
            <circle cx="113.987" cy="103.545" r="0.87013" fill="#343A40" />
            <circle cx="113.987" cy="113.987" r="0.87013" fill="#343A40" />
            <circle cx="113.987" cy="124.429" r="0.87013" fill="#343A40" />
            <circle cx="124.429" cy="9.57142" r="0.87013" fill="#343A40" />
            <circle cx="124.429" cy="20.0131" r="0.87013" fill="#343A40" />
            <circle cx="124.429" cy="30.4545" r="0.87013" fill="#343A40" />
            <circle cx="124.429" cy="40.8961" r="0.87013" fill="#343A40" />
            <circle cx="124.429" cy="51.3377" r="0.87013" fill="#343A40" />
            <circle cx="124.429" cy="61.7793" r="0.87013" fill="#343A40" />
            <circle cx="124.429" cy="72.2208" r="0.87013" fill="#343A40" />
            <circle cx="124.429" cy="82.6624" r="0.87013" fill="#343A40" />
            <circle cx="124.429" cy="93.1039" r="0.87013" fill="#343A40" />
            <circle cx="124.429" cy="103.545" r="0.87013" fill="#343A40" />
          </g>
        </g>
      </svg>
      <svg
        style={{ position: 'absolute', bottom: -74, right: -134, zIndex: -1 }}
        width="371"
        height="177"
        viewBox="0 0 371 177"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.08">
          <mask
            id="mask1"
            masktype="alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="371"
            height="177"
          >
            <rect width="370.096" height="176.74" fill="#C4C4C4" />
          </mask>
          <g mask="url(#mask1)">
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 534.048 137.911)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 532.754 133.081)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 531.46 128.252)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 530.166 123.422)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 528.872 118.592)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 527.578 113.763)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 523.802 110.446)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 522.508 105.617)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 521.214 100.787)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 519.92 95.9574)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 518.625 91.1277)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 519.813 84.785)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 518.519 79.9554)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 517.225 75.1257)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 515.931 70.2961)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 514.637 65.4666)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 513.343 60.6368)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 509.567 57.3203)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 508.273 52.4907)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 506.979 47.661)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 505.685 42.8314)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 504.391 38.0018)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 505.578 31.6592)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 504.284 26.8295)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 502.99 21.9999)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 501.696 17.1703)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 500.401 12.3406)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 499.107 7.51099)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 495.332 4.19446)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 494.038 -0.635254)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 492.744 -5.46484)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 491.45 -10.2944)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 490.155 -15.1241)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 491.343 -21.4668)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 490.049 -26.2964)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 488.755 -31.1261)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 487.461 -35.9557)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 486.167 -40.7853)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 484.873 -45.615)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 481.097 -48.9315)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 380.539 0.78894)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 379.245 -4.04077)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 377.951 -8.87036)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 376.657 -13.7)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 377.844 -20.0426)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 376.55 -24.8723)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 375.256 -29.7019)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 373.962 -34.5315)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 372.667 -39.3612)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 371.374 -44.1908)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 367.598 -47.5073)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 366.304 -52.3369)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 365.01 -57.1666)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 363.716 -61.9962)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 362.421 -66.8259)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 363.609 -73.1686)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 362.315 -77.9982)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 361.021 -82.8279)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 359.727 -87.6575)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 358.433 -92.4871)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 357.139 -97.3168)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 353.363 -100.633)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 352.069 -105.463)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 350.775 -110.293)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 349.48 -115.122)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 348.187 -119.952)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 350.668 -121.465)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 349.374 -126.294)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 345.599 -129.611)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 344.304 -134.441)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 343.01 -139.27)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 341.716 -144.1)"
              stroke="#343A40"
            />
            <line
              y1="-0.5"
              x2="529.469"
              y2="-0.5"
              transform="matrix(-0.873699 0.486466 -0.513657 -0.857996 340.422 -148.93)"
              stroke="#343A40"
            />
          </g>
        </g>
      </svg>
    </div>
  </section>
)

export default ExplanationSection

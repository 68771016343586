import React, { useMemo, useState } from 'react'
import styles from './ControlsItem.module.scss'

const ControlsItem = ({
  children,
  title,
  isOpened,
  setSelectedIndex,
  idx,
  selectedIndex
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const currentBG = useMemo(() => {
    if (isOpened)
      return (
        <svg
          width="428"
          height="175"
          viewBox="0 0 428 175"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20 175C8.95431 175 0 166.046 0 155V20C0 8.9543 8.95431 0 20 0H387.08C392.179 0 397.085 1.94719 400.795 5.44358L421.715 25.1548C425.726 28.9339 428 34.2005 428 39.7112V140.546V155C428 166.046 419.046 175 408 175H395.018H20Z"
            fill="url(#paint0_linear66)"
          />
          <defs>
            <linearGradient
              id="paint0_linear66"
              x1="36.8761"
              y1="139.735"
              x2="436.421"
              y2="-154.877"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#189DFE" />
              <stop offset="1" stopColor="#2241D5" />
            </linearGradient>
          </defs>
        </svg>
      )
    else
      return (
        <svg
          width="342"
          height="40"
          viewBox="0 0 342 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.5 3C0.5 1.61929 1.61929 0.5 3 0.5H322C332.77 0.5 341.5 9.23045 341.5 20C341.5 30.7696 332.77 39.5 322 39.5H3.00001C1.6193 39.5 0.5 38.3807 0.5 37V3Z"
            fill={isHovered ? "url(#paint0_linear123123)": 'white'}
            stroke="url(#paint0_linear55)"
          />
          <defs>
            <linearGradient
              id="paint0_linear123123"
              x1="29.4665"
              y1="31.9399"
              x2="93.934"
              y2="-134.243"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#189DFE" />
              <stop offset="1" stopColor="#2241D5" />
            </linearGradient>
            <linearGradient
              id="paint0_linear55"
              x1="29.4664"
              y1="31.9394"
              x2="93.9338"
              y2="-134.243"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#189DFE" />
              <stop offset="1" stopColor="#2241D5" />
            </linearGradient>
          </defs>
        </svg>
      )
  }, [isHovered, isOpened])
  const handleClick = idx => {
    setIsHovered(false)
    if (selectedIndex !== idx) setSelectedIndex(idx)
  }
  return (
    <div
      onMouseEnter={() => !isOpened && setIsHovered(true)}
      onMouseLeave={() => !isOpened && setIsHovered(false)}
      onClick={() => handleClick(idx)}
      className={
        isOpened
          ? [styles.itemWrapper, styles.itemSelected].join(' ')
          : styles.itemWrapper
      }
    >
      <div style={isHovered ? {color: "white"} : null} className={styles.title}>{title}</div>
      <div className={styles.content}>{children}</div>
      <div className={styles.controls}>
        <svg
          onClick={() => setSelectedIndex((selectedIndex - 1 + 4) % 4)}
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.98931 4.15989C7.82306 4.16239 7.66431 4.22989 7.54681 4.34739L1.1468 10.7474C0.979305 10.9074 0.911805 11.1461 0.970555 11.3711C1.0293 11.5949 1.2043 11.7699 1.42805 11.8286C1.65305 11.8874 1.8918 11.8199 2.0518 11.6524L7.99931 5.70489L13.9468 11.6524C14.1068 11.8199 14.3456 11.8874 14.5706 11.8286C14.7943 11.7699 14.9693 11.5949 15.0281 11.3711C15.0868 11.1461 15.0193 10.9074 14.8518 10.7474L8.45181 4.34739C8.32931 4.22489 8.16306 4.15739 7.98931 4.15989Z"
            fill="white"
          />
        </svg>
        <svg
          onClick={() => setSelectedIndex((selectedIndex + 1) % 4)}
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.3953 4.14988C14.2253 4.15488 14.0653 4.22488 13.9478 4.34738L8.00026 10.2949L2.05276 4.34738C1.93151 4.22363 1.76651 4.15363 1.59276 4.15363C1.33276 4.15363 1.09901 4.31238 1.00151 4.55363C0.902756 4.79488 0.961506 5.07113 1.14776 5.25238L7.54776 11.6524C7.79776 11.9024 8.20276 11.9024 8.45276 11.6524L14.8528 5.25238C15.0428 5.06988 15.1028 4.78863 15.0003 4.54488C14.899 4.30113 14.659 4.14488 14.3953 4.14988Z"
            fill="white"
          />
        </svg>
      </div>
      <div className={styles.bg}>{currentBG}</div>
    </div>
  )
}

export default ControlsItem

import React from 'react'
import styles from './SaveMoneySection.module.scss'
import Button from '../Button/Button'
import save_money_card_1 from '../../assets/save_money_card_1.svg'
import save_money_card_2 from '../../assets/save_money_card_2.svg'
import { reachGoal } from '../metrika'

const SaveMoneySection = ({ setShowPopup }) => (
  <section className={styles.section}>
    <div className={styles.sectionWrapper}>
      <div className={styles.label}>save money</div>
      <div className={styles.title}>Brand protection</div>
      <div className={styles.content}>
        A publisher uses brand bidding, client goes to your website with <br />
        a partner link (directly / through the publisher’s showcase / through
        <br /> the publisher’s coupon-site). As a result you pay the publisher
        <br />
        for the orders that you receive in any case and bids rise <br /> in
        contextual advertising auction.
      </div>
      <Button
        onClick={() => {
          setShowPopup(true)
          reachGoal('REG_FORM_BTN_4')
        }}
      >
        protect Brand
      </Button>
      <img className={styles.card_1} src={save_money_card_1} alt="" />
      <img className={styles.card_2} src={save_money_card_2} alt="" />
    </div>
    <div className={styles.bg}>
      <div className={styles.checks}>
        <div className={styles.check}>
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0)">
              <path
                d="M10.6055 2.35547L4.125 8.83594L1.39453 6.10547L0.855469 6.64453L3.85547 9.64453L4.125 9.90234L4.39453 9.64453L11.1445 2.89453L10.6055 2.35547Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="12" height="12" fill="white" />
              </clipPath>
            </defs>
          </svg>
          context on coupon area
        </div>
        <div className={styles.check}>
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0)">
              <path
                d="M10.6055 2.35547L4.125 8.83594L1.39453 6.10547L0.855469 6.64453L3.85547 9.64453L4.125 9.90234L4.39453 9.64453L11.1445 2.89453L10.6055 2.35547Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="12" height="12" fill="white" />
              </clipPath>
            </defs>
          </svg>
          context on cashback site
        </div>
        <div className={styles.check}>
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0)">
              <path
                d="M10.6055 2.35547L4.125 8.83594L1.39453 6.10547L0.855469 6.64453L3.85547 9.64453L4.125 9.90234L4.39453 9.64453L11.1445 2.89453L10.6055 2.35547Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="12" height="12" fill="white" />
              </clipPath>
            </defs>
          </svg>
          direct context
        </div>
      </div>
      <svg width="1000" height="499" viewBox="0 0 1000 499" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.77778e-06 468.226L4.10887e-05 30.0001C4.25372e-05 13.4315 13.4315 6.63393e-05 30 6.77878e-05L970 8.893e-05C986.569 9.03785e-05 1000 13.4315 1000 30.0001L1000 414.188C1000 430.088 987.595 443.226 971.722 444.139L31.7217 498.176C14.5048 499.166 1.27014e-06 485.471 2.77778e-06 468.226Z" fill="#263D4C"/>
      </svg>
    </div>
  </section>
)

export default SaveMoneySection

import React, { useReducer, useMemo, useCallback, useState } from 'react'
import axios from 'axios'
import styles from './Form.module.scss'
import Button from '../Button/Button'
import form_img from '../../assets/form_img.svg'
import { reachGoal } from '../metrika'

const initialState = {
  selectedPage: 0,
  data: {
    name: '',
    surname: '',
    email: '',
    phone: ''
  },
  validation: {
    name: false,
    email: false
  }
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'setSelectedPage':
      return { ...state, selectedPage: action.payload }
    case 'setData':
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload
        }
      }
    case 'setValidation': {
      return {
        ...state,
        validation: {
          ...state.validation,
          ...action.payload
        }
      }
    }
    default:
      return state
  }
}

const Page0 = ({ name, surname, dispatch, handleSubmit,validation }) => (
  <div className={styles.pageWrapper}>
    <div className={styles.inputsWrapper}>
      <p className={styles.label}>
        Name <span className={styles.star}>*</span>
      </p>
      <input
        style={validation.name ? {borderColor: '#FF7B7B'} : null}
        onChange={e => {
          dispatch({ type: 'setData', payload: { name: e.target.value } })
          dispatch({ type: 'setValidation', payload: { name: false } })
        }}
        value={name}
        className={styles.input}
        type="text"
        placeholder="Your name"
      />
    </div>
    <div className={styles.inputsWrapper}>
      <p className={styles.label}>Surname</p>
      <input
        onChange={e =>
          dispatch({ type: 'setData', payload: { surname: e.target.value } })
        }
        value={surname}
        className={styles.input}
        type="text"
        placeholder="Your surname"
      />
    </div>
    <Button
      onClick={handleSubmit}
      style={{
        width: 302,
        marginTop: 45
      }}
    >
      next
    </Button>
  </div>
)
const Page1 = ({ handleSubmit, dispatch, email, phone, validation }) => (
  <div className={styles.pageWrapper}>
    <div className={styles.inputsWrapper}>
      <p className={styles.label}>
        Email <span className={styles.star}>*</span>
      </p>
      <input
        style={validation.email ? {borderColor: '#FF7B7B'} : null}
        onChange={e => {
          dispatch({ type: 'setData', payload: { email: e.target.value } })
          dispatch({ type: 'setValidation', payload: { email: false } })
        }}
        value={email}
        className={styles.input}
        type="email"
        placeholder="Your email"
      />
    </div>
    <div className={styles.inputsWrapper}>
      <p className={styles.label}>Phone</p>
      <input
        onChange={e => {
          const re = /^[0-9\b]+$/

          if (
            (e.target.value === '' || re.test(e.target.value)) &&
            e.target.value.length <= 20
          ) {
            return dispatch({
              type: 'setData',
              payload: { phone: e.target.value }
            })
          }
        }}
        value={phone}
        className={styles.input}
        type="tel"
        placeholder="Your phone number"
      />
    </div>
    <Button
      type={'submit'}
      onClick={handleSubmit}
      style={{
        width: 302,
        marginTop: 45
      }}
    >
      next
    </Button>
  </div>
)
const Page2 = ({ handleSubmit, dispatch, name, surname, email, phone }) => (
  <div className={styles.pageWrapper}>
    <div className={styles.summaryItem}>
      <span className={styles.name}>{surname ? 'Full name' : 'Name'}:</span>
      {name} {surname}
    </div>
    <div className={styles.summaryItem}>
      <span className={styles.name}>Email:</span>
      {email}
    </div>
    {phone ? (
      <div className={styles.summaryItem}>
        <span className={styles.name}>Phone:</span>
        {phone}
      </div>
    ) : null}
    <Button
      type={'submit'}
      onClick={handleSubmit}
      style={{
        width: 302,
        marginTop: 85
      }}
    >
      ok
    </Button>
    <div className={styles.consent}>
      By clicking on the button,{' '}
      <a
        href="https://advcake.com/confend.pdf"
        target="_blank"
        rel="noopener noreferrer"
        className={styles.link}
      >
        you consent
      </a>
      <br />
      to process your personal data
    </div>
  </div>
)

const Form = ({ style, requestCallback }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [showDone, setShowDone] = useState(false)
  const handleSubmit = useCallback(
    async e => {
      e.preventDefault()
      // eslint-disable-next-line default-case
      switch (state.selectedPage) {
        case 0:
          if (state.data.name.length < 1) {
            dispatch({ type: 'setValidation', payload: { name: true } })
            return
          }
          dispatch({ type: 'setSelectedPage', payload: 1 })
          reachGoal('GO_TO_PAGE_2')
          break
        case 1:
          if (
            !state.data.email.match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
          ) {
            dispatch({ type: 'setValidation', payload: { email: true } })
            return
          }
          dispatch({ type: 'setSelectedPage', payload: 2 })
          reachGoal('GO_TO_PAGE_3')
          break
        case 2:
          setShowDone(true)
          await axios.post('https://form.advcake.com/mail.php', {
            name: state.data.surname
              ? `${state.data.name} ${state.data.surname}`
              : state.data.name,
            ...(state.data.phone && { phone: state.data.phone }),
            email: state.data.email,
            page: 'Landing'
          })
          dispatch({ type: 'setSelectedPage', payload: 0 })
          dispatch({
            type: 'setData',
            payload: { name: '', surname: '', email: '', phone: '' }
          })
          reachGoal('SEND_DATA')
          setTimeout(() => {
            setShowDone(false)
            if (requestCallback) requestCallback()
          }, 2500)
          break
      }
    },
    [
      requestCallback,
      state.data.email,
      state.data.name,
      state.data.phone,
      state.data.surname,
      state.selectedPage
    ]
  )
  const isPageDone = useMemo(() => {
    return {
      0: false,
      1: false,
      ...(state.data.name.length >= 1 && {
        0: true
      }),
      ...(state.data.email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ) && {
        1: true
      })
    }
  }, [state.data.email, state.data.name])

  const page = useMemo(() => {
    switch (state.selectedPage) {
      case 0:
        return (
          <Page0
            handleSubmit={handleSubmit}
            dispatch={dispatch}
            name={state.data.name}
            surname={state.data.surname}
            validation={state.validation}
          />
        )
      case 1:
        return (
          <Page1
            handleSubmit={handleSubmit}
            dispatch={dispatch}
            email={state.data.email}
            phone={state.data.phone}
            validation={state.validation}
          />
        )
      case 2:
        return (
          <Page2
            handleSubmit={handleSubmit}
            dispatch={dispatch}
            name={state.data.name}
            surname={state.data.surname}
            email={state.data.email}
            phone={state.data.phone}
          />
        )
      default:
        return null
    }
  }, [handleSubmit, state.data.email, state.data.name, state.data.phone, state.data.surname, state.selectedPage, state.validation])

  return (
    <form style={style} className={styles.form}>
      <div className={styles.title}>Registration</div>
      <div className={styles.controls}>
        <svg
          onClick={() => dispatch({ type: 'setSelectedPage', payload: 0 })}
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={state.selectedPage !== 0 ? styles.hoverable : null}
        >
          {isPageDone['0'] ? (
            <path
              d="M8.53819 13.114C8.2497 12.8168 7.77488 12.8097 7.47764 13.0982C7.18041 13.3867 7.17332 13.8615 7.46181 14.1587L8.53819 13.114ZM12.2353 18L11.6971 18.5224L12.2353 19.0769L12.7735 18.5224L12.2353 18ZM20.5382 10.5224C20.8267 10.2251 20.8196 9.7503 20.5224 9.46181C20.2251 9.17332 19.7503 9.18041 19.4618 9.47764L20.5382 10.5224ZM7.46181 14.1587L11.6971 18.5224L12.7735 17.4776L8.53819 13.114L7.46181 14.1587ZM12.7735 18.5224L20.5382 10.5224L19.4618 9.47764L11.6971 17.4776L12.7735 18.5224Z"
              fill="#169CFE"
            />
          ) : (
            <circle
              r="6"
              transform="matrix(-1 0 0 1 14 14.3633)"
              opacity={state.selectedPage === 0 ? 1 : 0.12}
              fill={state.selectedPage === 0 ? '#169CFE' : '#263D4C'}
            />
          )}
          <path
            d="M24.3416 5.32237C22.5091 3.13843 20.0322 1.59071 17.2659 0.901007C14.4997 0.211308 11.5861 0.415045 8.94281 1.48302C6.29948 2.55099 4.06211 4.42838 2.55135 6.84609C1.04059 9.26381 0.334015 12.0977 0.532886 14.9417C0.731756 17.7857 1.82587 20.4937 3.6584 22.6776C5.49094 24.8616 7.96782 26.4093 10.7341 27.099C13.5003 27.7887 16.4139 27.585 19.0572 26.517C21.7005 25.449 23.9379 23.5716 25.4487 21.1539"
            opacity={state.selectedPage === 0 ? 1 : 0.12}
            stroke={state.selectedPage === 0 ? '#169CFE' : '#263D4C'}
            strokeLinecap="round"
          />
        </svg>
        <div className={styles.dash}></div>
        <svg
          onClick={() => {
            if (isPageDone['0']) {
              dispatch({ type: 'setSelectedPage', payload: 1 })
              reachGoal('GO_TO_PAGE_2')
            }
          }}
          className={
            state.selectedPage !== 1 && isPageDone['0']
              ? styles.hoverable
              : null
          }
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.67795 21.3526C3.95555 23.32 5.72373 24.9204 7.80823 25.9963C9.89273 27.0722 12.2216 27.5864 14.5653 27.4882C16.909 27.3899 19.1867 26.6827 21.1739 25.4361C23.161 24.1896 24.7891 22.4468 25.8976 20.3794"
            strokeLinecap="round"
            opacity={state.selectedPage === 1 ? 1 : 0.12}
            stroke={state.selectedPage === 1 ? '#169CFE' : '#263D4C'}
          />
          <path
            d="M25.3221 6.64737C24.0444 4.68004 22.2763 3.07956 20.1918 2.00367C18.1073 0.927777 15.7784 0.413611 13.4347 0.511842C11.091 0.610073 8.81328 1.31731 6.82612 2.56385C4.83896 3.8104 3.2109 5.55322 2.1024 7.62056"
            strokeLinecap="round"
            opacity={state.selectedPage === 1 ? 1 : 0.12}
            stroke={state.selectedPage === 1 ? '#169CFE' : '#263D4C'}
          />
          {isPageDone['1'] ? (
            <path
              d="M8.53819 13.114C8.2497 12.8168 7.77488 12.8097 7.47764 13.0982C7.18041 13.3867 7.17332 13.8615 7.46181 14.1587L8.53819 13.114ZM12.2353 18L11.6971 18.5224L12.2353 19.0769L12.7735 18.5224L12.2353 18ZM20.5382 10.5224C20.8267 10.2251 20.8196 9.7503 20.5224 9.46181C20.2251 9.17332 19.7503 9.18041 19.4618 9.47764L20.5382 10.5224ZM7.46181 14.1587L11.6971 18.5224L12.7735 17.4776L8.53819 13.114L7.46181 14.1587ZM12.7735 18.5224L20.5382 10.5224L19.4618 9.47764L11.6971 17.4776L12.7735 18.5224Z"
              fill="#169CFE"
            />
          ) : (
            <circle
              r="6"
              transform="matrix(-1 0 0 1 14 14.3633)"
              opacity={state.selectedPage === 1 ? 1 : 0.12}
              fill={state.selectedPage === 1 ? '#169CFE' : '#263D4C'}
            />
          )}
        </svg>
        <div className={styles.dash}></div>
        <svg
          onClick={() => {
            if (isPageDone['0'] && isPageDone['1']) {
              dispatch({ type: 'setSelectedPage', payload: 2 })
              reachGoal('GO_TO_PAGE_3')
            }
          }}
          className={
            state.selectedPage !== 2 && isPageDone['1']
              ? styles.hoverable
              : null
          }
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity={state.selectedPage === 2 ? 1 : 0.12}
            stroke={state.selectedPage === 2 ? '#169CFE' : '#263D4C'}
            d="M3.6584 22.6776C5.49094 24.8616 7.96782 26.4093 10.7341 27.099C13.5003 27.7887 16.4139 27.585 19.0572 26.517C21.7005 25.449 23.9379 23.5716 25.4487 21.1539C26.9594 18.7362 27.666 15.9023 27.4671 13.0583C27.2682 10.2143 26.1741 7.5063 24.3416 5.32236C22.5091 3.13843 20.0322 1.59071 17.2659 0.901007C14.4997 0.211308 11.5861 0.415045 8.94281 1.48302C6.29948 2.55099 4.06211 4.42838 2.55135 6.84609"
            strokeLinecap="round"
          />
          <circle
            r="6"
            transform="matrix(-1 0 0 1 14 14.3633)"
            opacity={state.selectedPage === 2 ? 2 : 0.12}
            fill={state.selectedPage === 2 ? '#169CFE' : '#263D4C'}
          />
        </svg>
      </div>
      {page}
      <div
        style={{
          opacity: showDone ? 1 : 0,
          pointerEvents: showDone ? 'all' : 'none'
        }}
        className={styles.formDone}
      >
        <div className={styles.formDoneTitle}>Done!</div>
        <div className={styles.formDoneSubtitle}>
          Registration successfully <br />
          completed!
        </div>
        <img className={styles.img} src={form_img} alt="" />
      </div>
    </form>
  )
}

export default Form

import React, { useState, useMemo, useRef } from 'react'
import styles from './CarouselSection.module.scss'
import ControlsItem from './ControlsItem'

import img0 from '../../assets/Carousel/0.svg'
import img1 from '../../assets/Carousel/1.svg'
import img2 from '../../assets/Carousel/2.svg'
import img3 from '../../assets/Carousel/3.svg'

const CarouselSection = () => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const imgRef = useRef(null)
  const currentImg = useMemo(() => {
    switch (selectedIndex) {
      case 0:
        return img0
      case 1:
        return img1
      case 2:
        return img2
      case 3:
        return img3
      default:
        return null
    }
  }, [selectedIndex])

  const imgOnLoad = () => {
    let deg
    switch (selectedIndex) {
      case 0: {
        deg = 5
        break
      }
      case 1: {
        deg = -5
        break
      }
      case 2: {
        deg = 5
        break
      }
      case 3: {
        deg = -5
        break
      }
      default: deg = 5
    }
    imgRef.current.style.opacity = 1
    imgRef.current.style.transform = `rotate(${deg}deg)`
  }

  return (
    <section className={styles.section}>
      <div className={styles.sectionWrapper}>
        <img ref={imgRef} onLoad={imgOnLoad} className={styles.carousel} src={currentImg} alt="" />
        <div className={styles.controls}>
          <ControlsItem
            idx={0}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            isOpened={selectedIndex === 0}
            title="Order statistics"
          >
            Detailed statistics for each order
            <br/>
            <br/>
            Cancel all fraud orders
          </ControlsItem>
          <ControlsItem
            idx={1}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            isOpened={selectedIndex === 1}
            title="Publisher statistics"
          >
            Statistics with recommendations for each webmaster.
            <br />
            <br />
            Block all intruders.
          </ControlsItem>
          <ControlsItem
            idx={2}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            isOpened={selectedIndex === 2}
            title="Partner statistics"
          >
            Statistics for each marketing channel
            <br />
            <br />
            Find the most profitable
          </ControlsItem>
          <ControlsItem
            idx={3}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            isOpened={selectedIndex === 3}
            title="Session History"
          >
            Evidence of violations for each order
            <br />
            <br />
            Send them to your CPA network manager
          </ControlsItem>
        </div>
      </div>
    </section>
  )
}

export default CarouselSection

import React from 'react'
import styles from './SolutionSection.module.scss'
import Button from '../Button/Button'

import solutions_dots from '../../assets/solutions_dots.svg'
import { reachGoal } from '../metrika'

const SolutionSection = ({setShowPopup}) => (
  <section className={styles.section}>
    <div className={styles.sectionWrapper}>
      <div className={styles.title}>
        Best Solution for <br /> Affiliate Marketing
      </div>
      <div className={styles.content}>
        Adv.Cake Is an Intelligent Antifraud and Brand Bidding, <br />
        Monitoring Solution for Affiliate Marketing
      </div>
      <Button onClick={() => {setShowPopup(true);reachGoal('REG_FORM_BTN_1')}}>try it</Button>
      <div className={[styles.cardWhite, styles.card].join(' ')}>
        We save up to 60% of your budget by <br />
        protecting the business from <br />
        unscrupulous affiliates.
      </div>
      <div className={[styles.cardBlue, styles.card].join(' ')}>
        Over the three years of operation, <br />
        we have saved more than <br />
        $3 million for our clients.
      </div>
    </div>
    <div className={styles.bg}>
      <svg width="1000" height="499" viewBox="0 0 1000 499" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.77778e-06 468.226L4.10887e-05 30.0001C4.25372e-05 13.4315 13.4315 6.63393e-05 30 6.77878e-05L970 8.893e-05C986.569 9.03785e-05 1000 13.4315 1000 30.0001L1000 414.188C1000 430.088 987.595 443.226 971.722 444.139L31.7217 498.176C14.5048 499.166 1.27014e-06 485.471 2.77778e-06 468.226Z" fill="#263D4C"/>
      </svg>
    </div>
    <img className={styles.dots} src={solutions_dots} alt=""/>
  </section>
)

export default SolutionSection
